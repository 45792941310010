/* InstallPrompt.css */
.brandedPromptOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .brandedPromptContainer {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 8px;
    border-radius: 10px;
    text-align: center;
    width: 85vw;
    max-width: 400px;
    box-shadow:0 0 20px rgba(0, 0, 0, 0.3);
  }

  .promptButtonsContainer {
    display:flex;
    flex-direction: row;
  }
  
  .acceptButton, .closeButton {
    margin: 8px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .acceptButton {
    background-color: var(--colorPrimaryButton);
  }
  
  .closeButton {
    background-color: var(--colorGrey300);

  }
  