/* InstallPrompt.css */
.install-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .install-prompt-container {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 85vw;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .install-button, .close-button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .install-button {
    /* background-color: #007bff;
     */
    background-color: var(--colorPrimaryButton);
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .close-button {
    background-color: var(--colorGrey300);
    color: white;
    border: none;
    border-radius: 5px;
  }
  